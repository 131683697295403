body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'Caslon';
    src: url(./fonts/BigCaslon-Medium.ttf);
}

